
import { defineComponent, PropType } from 'vue';
import { Turn } from '@/models/Turn';

export default defineComponent({
  props: {
    turn: {
      type: Object as PropType<Turn>,
      required: true,
    },
  },

  emits: ['update', 'delete', 'rollInitiative'],
});
