<template>
  <transition-group name="turn-list" tag="div" class="turn-list">
    <initiative-turn
      v-for="turn in currentEncounter"
      :key="turn.id"
      :turn="turn"
      @delete="turnCRUD.del"
    ></initiative-turn>
  </transition-group>

  <!-- <initiative-input @submit="turnCRUD.create"></initiative-input> -->

  <button @click="sortTurns(['initiative', 'modifier', 'title'], 'desc')">
    Sort Turns
  </button>
  <button @click="populateSampleData">Populate Sample Data</button>
  <button @click="saveCurrentEncounter" v-bind:disabled="!encounterSaveEnabled">
    Save Encounter
  </button>
  <button @click="turnCRUD.deleteAll">Clear Encounter</button>

  <hr />

  <h2>Saved Encounters</h2>
  <p v-for="encounter in savedEncounters" v-bind:key="encounter.id">
    {{ encounter.name }}
    <button @click="loadEncounter(encounter)">Load</button>
    <button @click="encounterCRUD.del(encounter)">X</button>
  </p>
</template>

<style lang="scss" scoped>
hr {
  margin: 2rem;
}

.turn-list {
  margin-bottom: 2rem;
}

.turn-list-enter-active,
.turn-list-leave-active {
  transition: all 0.5s ease-in-out;
}
.turn-list-enter-from {
  opacity: 0;
  transform: translateX(-5%);
}
.turn-list-leave-to {
  opacity: 0;
  transform: translateX(5%);
}
.turn-list-move {
  transition: transform 0.5s ease-in-out;
}
</style>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useStorage } from '@vueuse/core';
import { deepCopy } from '@/utilities/deepCopy';
import { useCRUD } from '@/composables/useCRUD';
import { useRandomTurn } from '@/composables/useRandomTurn';
import { useSort } from '@/composables/useSort';
import { Turn } from '@/models/Turn';
import { Encounter } from '@/models/Encounter';
// import InitiativeInput from '@/components/InitiativeInput.vue';
import InitiativeTurn from '@/components/InitiativeTurn.vue';

export default defineComponent({
  components: {
    // InitiativeInput,
    InitiativeTurn,
  },

  setup() {
    const currentEncounter = useStorage(
      'initiative-tracker-current-encounter',
      [] as Turn[]
    );
    const savedEncounters = useStorage(
      'initiative-tracker-saved-encounters',
      [] as Encounter[]
    );

    const turnCRUD = useCRUD(currentEncounter);
    const { sortItems: sortTurns } = useSort(currentEncounter);

    const encounterCRUD = useCRUD(savedEncounters);

    const saveCurrentEncounter = () => {
      const name = prompt('Saving current encounter. Enter a name:');
      if (name === null) return;
      encounterCRUD.create({ name, turns: deepCopy(currentEncounter.value) });
    };

    const encounterSaveEnabled = computed(
      () => currentEncounter.value.length > 0
    );

    const loadEncounter = (encounter: Encounter) => {
      const msg =
        `Loading "${encounter.name}" will erase the current encounter! ` +
        'Are you sure you want to continue?';
      if (!confirm(msg)) return;
      turnCRUD.deleteAll();
      currentEncounter.value = deepCopy(encounter.turns);
    };

    const { generateRandomTurns } = useRandomTurn();

    const populateSampleData = () =>
      generateRandomTurns(4, 9).map(turnCRUD.create);

    return {
      currentEncounter,
      turnCRUD,
      sortTurns,
      savedEncounters,
      encounterCRUD,
      saveCurrentEncounter,
      encounterSaveEnabled,
      loadEncounter,
      populateSampleData,
    };
  },
});
</script>
