import { Ref } from 'vue';
import { nativeMath, uuid4 } from 'random-js';

export function useCRUD<T extends { id: string }>(list: T[] | Ref<T[]>) {
  type Input<U> = Omit<U, 'id'>;

  const store = () => ('value' in list ? list.value : list);
  const uuid = () => uuid4(nativeMath);

  const create = (item: Input<T>) => store().push({ id: uuid(), ...item } as T);
  const read = (id: string) => store().find(x => x.id === id);
  const update = (item: T) => Object.assign(read(item.id), item);
  const del = (item: T) => {
    const index = store().findIndex(x => x === item);
    if (index >= 0) store().splice(index, 1);
  };

  const deleteAll = () => (store().length = 0);

  return {
    create,
    read,
    update,
    del,
    deleteAll,
  };
}
