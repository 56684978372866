<template>
  <article class="initiative-turn">
    <h1 class="initiative-turn-title">{{ turn.title }}</h1>
    <p class="initiative-turn-initiative">{{ turn.initiative }}</p>
    <div class="initiative-turn-actions">
      <button @click="$emit('delete', turn)">&times;</button>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use 'sass:color';
@use '../assets/styles/variables/colors';
@use '../assets/styles/variables/fonts';

.initiative-turn {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 25rem;
  margin: 0.5rem auto;
  padding: 0.5rem 1rem;
  border: color.adjust(colors.$bg-light, $lightness: -1) 1px solid;
  background-color: color.adjust(colors.$bg-light, $lightness: 2);
  box-shadow: 0.1rem 0.1rem 0.25rem rgba(0, 0, 0, 0.1);

  @media (prefers-color-scheme: dark) {
    border: color.adjust(colors.$bg-dark, $lightness: -1) 1px solid;
    background-color: color.adjust(colors.$bg-dark, $lightness: 2);
    box-shadow: 0 0 0.25rem rgba(255, 255, 255, 0.1) inset;
  }
}

.initiative-turn-title {
  margin: 0;
  font-family: fonts.$info-headings;
  font-size: 1.5rem;
}

.initiative-turn-initiative {
  position: relative;
  top: -0.13rem;
  margin: 0;
  margin-left: auto;
  font-size: 2rem;
}

.initiative-turn-actions {
  position: absolute;
  left: 100%;
  top: 52%;
  transform: translate(50%, -50%);
}

button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  border: none;
  background: none;
  color: colors.$red;
  font-size: 2rem;
}
</style>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Turn } from '@/models/Turn';

export default defineComponent({
  props: {
    turn: {
      type: Object as PropType<Turn>,
      required: true,
    },
  },

  emits: ['update', 'delete', 'rollInitiative'],
});
</script>
