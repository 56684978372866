
import { computed, defineComponent } from 'vue';
import { useStorage } from '@vueuse/core';
import { deepCopy } from '@/utilities/deepCopy';
import { useCRUD } from '@/composables/useCRUD';
import { useRandomTurn } from '@/composables/useRandomTurn';
import { useSort } from '@/composables/useSort';
import { Turn } from '@/models/Turn';
import { Encounter } from '@/models/Encounter';
// import InitiativeInput from '@/components/InitiativeInput.vue';
import InitiativeTurn from '@/components/InitiativeTurn.vue';

export default defineComponent({
  components: {
    // InitiativeInput,
    InitiativeTurn,
  },

  setup() {
    const currentEncounter = useStorage(
      'initiative-tracker-current-encounter',
      [] as Turn[]
    );
    const savedEncounters = useStorage(
      'initiative-tracker-saved-encounters',
      [] as Encounter[]
    );

    const turnCRUD = useCRUD(currentEncounter);
    const { sortItems: sortTurns } = useSort(currentEncounter);

    const encounterCRUD = useCRUD(savedEncounters);

    const saveCurrentEncounter = () => {
      const name = prompt('Saving current encounter. Enter a name:');
      if (name === null) return;
      encounterCRUD.create({ name, turns: deepCopy(currentEncounter.value) });
    };

    const encounterSaveEnabled = computed(
      () => currentEncounter.value.length > 0
    );

    const loadEncounter = (encounter: Encounter) => {
      const msg =
        `Loading "${encounter.name}" will erase the current encounter! ` +
        'Are you sure you want to continue?';
      if (!confirm(msg)) return;
      turnCRUD.deleteAll();
      currentEncounter.value = deepCopy(encounter.turns);
    };

    const { generateRandomTurns } = useRandomTurn();

    const populateSampleData = () =>
      generateRandomTurns(4, 9).map(turnCRUD.create);

    return {
      currentEncounter,
      turnCRUD,
      sortTurns,
      savedEncounters,
      encounterCRUD,
      saveCurrentEncounter,
      encounterSaveEnabled,
      loadEncounter,
      populateSampleData,
    };
  },
});
