import { nativeMath, integer, pick, bool } from 'random-js';
import { Turn } from '@/models/Turn';
import { characterNames, enemyNames } from '@/assets/sample-names.json';

export function useRandomTurn() {
  type Input<U> = Omit<U, 'id'>;

  const generateRandomTurn = (): Input<Turn> => {
    const enemyTurn = bool(0.33)(nativeMath);
    return {
      title: pick(nativeMath, enemyTurn ? enemyNames : characterNames),
      initiative: integer(0, 24)(nativeMath),
      modifier: integer(-2, 7)(nativeMath),
      advantage: bool(0.7)(nativeMath),
      plus1d10: bool(0.1)(nativeMath),
    };
  };

  const generateRandomTurns = (
    countOrMin: number,
    max?: number
  ): Input<Turn>[] => {
    const count = integer(countOrMin, max || countOrMin)(nativeMath);
    const turns: Input<Turn>[] = [];
    for (let i = 0; i < count; i++) {
      turns.push(generateRandomTurn());
    }
    return turns;
  };

  return {
    generateRandomTurn,
    generateRandomTurns,
  };
}
