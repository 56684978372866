import { Ref } from 'vue';

function compareBy<U>(a: U, b: U, key: keyof U) {
  const u = a[key];
  const v = b[key];
  if (typeof u === 'string' && typeof v === 'string') return u.localeCompare(v);
  if (typeof u === 'number' && typeof v === 'number') return u - v;
  if (typeof u === 'boolean' && typeof v === 'boolean')
    return u && v ? 0 : u && !v ? -1 : 1;
  return 0;
}

function compareByMultiple<U>(a: U, b: U, keys: (keyof U)[]) {
  for (let i = 0; i < keys.length; i++) {
    const comp = compareBy<U>(a, b, keys[i]);
    if (comp !== 0) return comp;
  }
  return 0;
}

export function useSort<T>(list: T[] | Ref<T[]>) {
  type direction = 'ascending' | 'asc' | 'descending' | 'desc';

  const getList = () => ('value' in list ? list.value : list);

  function sortItems(by: keyof T, direction?: direction): void;
  function sortItems(by: (keyof T)[], direction?: direction): void;
  function sortItems(by: keyof T | (keyof T)[], direction?: direction): void {
    const sortBy = Array.isArray(by) ? by : [by];
    getList().sort((a: T, b: T) => compareByMultiple<T>(a, b, sortBy));
    if (direction === 'desc' || direction === 'descending') getList().reverse();
  }

  return { sortItems };
}
